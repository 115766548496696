import { EnvironmentParams } from './environment-params';
import { buildInfo } from './build-info';

export const environment: EnvironmentParams = {
  production: true,
  muteConsoleLog: true,
  buildInfo,
  rollbarConfig: {
    payload: {
      environment: 'prod',
      buildInfo,
      client: {
        javascript: {
          code_version: buildInfo.revision,
          version: buildInfo.revision
        }
      }
    },
    accessToken: 'fda9445e734341afaf65f7f50901699a',
    hostBlackList: ['.satismeter.com', 'localhost'],
    captureUncaught: true,
    captureUnhandledRejections: true
  },
  domain: 'hygger.io',
  apiUrl: 'https://accounts.hygger.io/api/',
  googleClientId:
    '99980416761-u091gq7rj1fumpatnuklfloclfiisjs8.apps.googleusercontent.com'
};
