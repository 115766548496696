"use strict";
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */
Object.defineProperty(exports, "__esModule", { value: true });
/***************************************************************************************************
 * BROWSER POLYFILLS
 */
/** IE9, IE10 and IE11 requires all of the following polyfills. **/
require("core-js/es6/symbol");
require("core-js/es6/object");
require("core-js/es6/function");
require("core-js/es6/parse-int");
require("core-js/es6/parse-float");
require("core-js/es6/number");
require("core-js/es6/math");
require("core-js/es6/string");
require("core-js/es6/date");
require("core-js/es6/array");
require("core-js/es7/array");
require("core-js/es6/regexp");
require("core-js/es6/map");
require("core-js/es6/set");
require("core-js/es7/object");
/** IE10 and IE11 requires the following for NgClass support on SVG elements */
require("classlist.js"); // Run `npm install --save classlist.js`.
/** IE10 and IE11 requires the following to support `@angular/animation`. */
require("web-animations-js"); // Run `npm install --save web-animations-js`.
/** Evergreen browsers require these. **/
require("core-js/es6/reflect");
require("core-js/es7/reflect");
/** ALL Firefox browsers require the following to support `@angular/animation`. **/
/**  it's pollyfill for web animations, it's require by many browsers, Chrome hasn't fully support it yet */
require("web-animations-js/web-animations-next.min.js"); // Run `npm install --save web-animations-js`.
var environment_1 = require("./environments/environment"); // Included with Angular CLI.
if (environment_1.environment.muteConsoleLog) {
    var forcedShowConsoleLog = false;
    try {
        forcedShowConsoleLog = !!localStorage.getItem('forcedShowConsoleLog');
    }
    catch (e) {
    }
    if (!forcedShowConsoleLog) {
        console.log = function () { };
        console.warn = function () { };
    }
}
/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
require("zone.js/dist/zone");
