"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var build_info_1 = require("./build-info");
exports.environment = {
    production: true,
    muteConsoleLog: true,
    buildInfo: build_info_1.buildInfo,
    rollbarConfig: {
        payload: {
            environment: 'prod',
            buildInfo: build_info_1.buildInfo,
            client: {
                javascript: {
                    code_version: build_info_1.buildInfo.revision,
                    version: build_info_1.buildInfo.revision
                }
            }
        },
        accessToken: 'fda9445e734341afaf65f7f50901699a',
        hostBlackList: ['.satismeter.com', 'localhost'],
        captureUncaught: true,
        captureUnhandledRejections: true
    },
    domain: 'hygger.io',
    apiUrl: 'https://accounts.hygger.io/api/',
    googleClientId: '99980416761-u091gq7rj1fumpatnuklfloclfiisjs8.apps.googleusercontent.com'
};
